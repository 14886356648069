<template>
  <button
    :disabled="buttonDisabled"
    class="button"
    :class="{
      [`button-${type}`]: true,
      active,
      ['button-size' + '--' + size]: size,
      disabled: buttonDisabled
    }"
  >
    <slot v-if="!loading" />
    <AppPreloader v-else />
  </button>
</template>

<script>
import AppPreloader from "@/components/ui/AppPreloader";
const buttonSizes = [
  "icon",
  "xs",
  "sm",
  "smL",
  "md-fluid",
  "md",
  "lg",
  "xl",
  "fluid"
];

export default {
  name: "AppButton",
  components: { AppPreloader },
  props: {
    loading: { type: Boolean, default: false },
    type: {
      type: String,
      validator: type => {
        return [
          "primary",
          "secondary",
          "danger",
          "success",
          "default",
          "primary-border",
          "primary-border-dashed"
        ].indexOf(type);
      },

      default: "default"
    },

    size: {
      type: String,
      validator(size) {
        return buttonSizes.includes(size);
      }
    },

    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      styles: this.styles
    };
  },

  computed: {
    buttonDisabled() {
      return this.disabled || this.loading;
    }
  }
};
</script>

<style lang="scss">
.button {
  color: $color-contrast-light;
  font-weight: 500;
  @include shadowBlock();
  padding-right: 1.2rem;
  transition: $transition;
  @extend %flex-center;

  &[disabled="true"],
  &[disabled],
  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}

@each $type, $styles in $buttonTypes {
  $style: map-get($styles, "base");
  $active: map-get($styles, "active");
  $hover: map-get($styles, "hover");
  $focus: map-get($styles, "focus");
  $activeState: map-get($styles, "activeState");

  .button {
    &-#{$type} {
      @include getButtonStyles($type);
    }
  }
}

@each $size, $styles in $buttonSizes {
  .button-size--#{$size} {
    @include getStyles($styles);
  }
}
</style>
